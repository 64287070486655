import { ComputedRef, computed } from '@vue/composition-api';
import { IVariant } from '~/types/fightcamp';
import variants from '@/shared/datasets/variants';

type ProductType = 'equip' | 'deal' | 'warranty' | 'subs_only';

interface ICartItem extends IVariant {
  id: string;
  qty: number;
  type: ProductType;
}

interface ICartActionOptions {
  delay?: boolean;
  type?: ProductType;
}

interface IUseCartContext {
  cartqty: ComputedRef<number>;
  isCartEmpty: ComputedRef<boolean>;
  isEquipInCart: ComputedRef<boolean>;
  cartDrawerOpen: ComputedRef<boolean>;
  packageInCartName: ComputedRef<string>;
  packageInCart: ComputedRef<boolean>;
  hasPackageWithBag: ComputedRef<boolean>;
  summaryTotal: ComputedRef<number>;
  summarySubtotal: ComputedRef<number>;
  cart: ComputedRef<ICartItem[]>;
  cartItems: ComputedRef<ICartItem[]>;
  toggleDrawer: () => void;
  insertItemToCart: (itemId: string, options?: ICartActionOptions) => Promise<void>;
  removeItemFromCart: (item: IVariant, options?: ICartActionOptions) => Promise<void>;
}

export function useCartContext(): IUseCartContext {
  // @ts-ignore
  const { $store } = useNuxtApp();

  const cartqty = computed(() => $store.getters.cartqty);
  const isCartEmpty = computed(() => cartqty.value === 0);
  const isEquipInCart = computed(() => $store.getters.isEquipInCart);
  const cartDrawerOpen = computed(() => $store.state.checkout.cartDrawerOpen);
  const packageInCartName = computed(() => $store.getters.packageInCartName);
  const packageInCart = computed(() => $store.getters.packageInCart);
  const hasPackageWithBag = computed(() => $store.getters.hasPackageWithBag);
  const summaryTotal = computed(() => $store.getters.summary_total);
  const summarySubtotal = computed(() => $store.getters.summary_subtotal);
  const cart = computed(() => $store.getters.cart);

  const cartItems: ComputedRef<ICartItem[]> = computed(() => cart.value.map((item: ICartItem) => {
    const desc = variants.find(product => item.id === product.id);
    return {
      ...desc,
      id: item.id,
      qty: item.qty,
      type: item.type,
    };
  }));

  const toggleDrawer = () => { $store.commit('closeDrawer'); };
  async function insertItemToCart(itemId: string, { delay = false, type = 'equip' }: ICartActionOptions = {}) {
    await $store.dispatch('insertItemToCart', {
      id: itemId,
      type,
      disableDrawer: true,
      delay,
    });
  }

  async function removeItemFromCart(item: IVariant, { delay = false }: ICartActionOptions = {}) {
    await $store.dispatch('removeItemFromCart', {
      ...item,
      delay,
    });
  }

  return {
    cartqty,
    isCartEmpty,
    isEquipInCart,
    cartDrawerOpen,
    toggleDrawer,
    packageInCartName,
    packageInCart,
    hasPackageWithBag,
    summaryTotal,
    summarySubtotal,
    cart,
    cartItems,
    insertItemToCart,
    removeItemFromCart,
  };
}
